import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { pageTabs } from 'pages/User/Routine/RoutineDetails/config';
import { getPerformanceDateTime } from 'utils/dates';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  routineInfo: {},
  currentTab: pageTabs[0].slug,
  dancers: {
    routineDancers: [],
    list: [],
    currentPage: 1,
  },
  performances: {
    routinePerformances: [],
    currentPage: 1,
  },

  audioUrl: '',
  recipients: [],
  loadingRoutineInfoStatus: status.IDLE,
  routineDeletingStatus: status.IDLE,
};

const slice = createSlice({
  name: 'routineDetails',
  initialState,
  reducers: {
    resetState(state) {
      Object.assign(state, initialState);
    },
    resetStatus(state, { payload }) {
      state.schedule[payload] = status.IDLE;
    },
    setAudioUrl(state, { payload }) {
      state.audioUrl = payload;
    },
    setPageTab(state, { payload }) {
      state.currentTab = payload;
    },
    setTablePage: (state, { payload }) => {
      state.schedule.pagination.current = payload;
    },
    setDancersPage: (state, { payload }) => {
      state.dancers.currentPage = payload;
    },
    setPerformancesPage: (state, { payload }) => {
      state.performances.currentPage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getRoutineInfo.pending, (state) => {
        state.loadingRoutineInfoStatus = status.PENDING;
      })
      .addCase(thunks.getRoutineInfo.fulfilled, (state, { payload }) => {
        state.routineInfo = payload.data;
        state.audioUrl = payload.data.music_url;
        state.loadingRoutineInfoStatus = status.SUCCESS;
      })
      .addCase(thunks.getRoutineInfo.rejected, (state) => {
        state.loadingRoutineInfoStatus = status.FAIL;
      })

      .addCase(thunks.getDancersList.fulfilled, (state, { payload }) => {
        state.dancers.list = payload.data;
      })
      .addCase(thunks.getRoutineDancers.fulfilled, (state, { payload }) => {
        state.dancers.routineDancers = payload.data;
      })

      .addCase(
        thunks.getRoutinePerformances.fulfilled,
        (state, { payload }) => {
          const { data } = payload;

          state.performances.routinePerformances = data.map((p) => ({
            ...p,
            name: p.event_data.name,
            location: p.event_data.location_formatted,
            dateTime: getPerformanceDateTime(p.date, p.time),
          }));
        }
      )

      .addCase(thunks.deleteRoutine.pending, (state) => {
        state.routineDeletingStatus = status.PENDING;
      })
      .addCase(thunks.deleteRoutine.fulfilled, (state) => {
        state.routineDeletingStatus = status.SUCCESS;
      })
      .addCase(thunks.deleteRoutine.rejected, (state) => {
        state.routineDeletingStatus = status.FAIL;
      })

      .addCase(thunks.getRecipients.fulfilled, (state, { payload }) => {
        state.recipients = payload.data.map((d) => ({
          value: d.id,
          label: d.fullName,
        }));
      })

      .addCase(thunks.uploadAudio.fulfilled, (state, { payload }) => {
        state.audioUrl = payload.data;
      })
      .addCase(thunks.removeAudio.fulfilled, (state) => {
        state.audioUrl = '';
      });
  },
});

const routineDetails = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { routineDetails };
export default slice.reducer;
