import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { WEB_API_ROUTES } from 'api/api-routes';
import { handleServerErrors } from 'utils/server-errors';

const getRoutinePerformanceDancers = createAsyncThunk(
  'createPerformance/getRoutinePerformanceDancers',
  async ({ routineId, performanceId }) => {
    try {
      const res = await api.get(
        WEB_API_ROUTES.performances.routineDancers
          .replace('{routineId}', routineId)
          .replace('{performanceId}', performanceId)
      );
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const submitCreation = createAsyncThunk(
  'createPerformance/submitCreation',
  async ({ id, data, isRoutine }) => {
    try {
      const endpoint = isRoutine
        ? WEB_API_ROUTES.performances.createRoutinePerformance.replace(
            '{routineId}',
            id
          )
        : WEB_API_ROUTES.performances.createEventPerformance.replace(
            '{eventId}',
            id
          );

      const res = await api.post(endpoint, data);
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getRoutinePerformanceDancers,
  submitCreation,
};

export { thunks };
