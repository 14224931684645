const guardians = (state) => {
  const pending = state.createDancer.guardians.filter(
    (g) => g.status === 'pending'
  );
  const saved = state.createDancer.guardians.filter(
    (g) => g.status === 'saved'
  );

  return { pending, saved };
};
const selectors = {
  guardians,
  relatives: (state) => state.createDancer.relatives,
  defaultDancerInfo: (state) => state.createDancer.defaultDancerInfo,
  createGuardianStatus: (state) => state.createDancer.createGuardianStatus,
  fetchingRelativesStatus: (state) =>
    state.createDancer.fetchingRelativesStatus,
  submitDancerCreationStatus: (state) =>
    state.createDancer.submitDancerCreationStatus,
};

export { selectors };
