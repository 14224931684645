const selectors = {
  step: (state) => state.registration.step,
  isPasswordRecoveryFlow: (state) => state.registration.isPasswordRecoveryFlow,
  addEmailConfirmation: (state) => state.registration.addEmailConfirmation,

  signUp: (state) => state.registration.signUp,
  phoneNumber: (state) => state.registration.phoneNumber,
  companyInfo: (state) => state.registration.companyInfo,
  profileInfo: (state) => state.registration.profileInfo,
  address: (state) => state.registration.address,
  website: (state) => state.registration.website,
  isLogin: (state) => state.registration.isLogin,
};

export { selectors };
