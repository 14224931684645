import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { WEB_API_ROUTES } from 'api/api-routes';
import { handleServerErrors } from 'utils/server-errors';
import { showToast } from 'utils/showToast';

const submitEventCreation = createAsyncThunk(
  'createEvent/submitEventCreation',
  async (data) => {
    try {
      const res = await api.post(WEB_API_ROUTES.events.create, data);
      showToast.success('New event created');
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const editEvent = createAsyncThunk(
  'createEvent/editEvent',
  async ({ eventId, data }) => {
    try {
      const res = await api.put(
        WEB_API_ROUTES.events.edit.replace('{eventId}', eventId),
        data
      );
      showToast.success('Event successfully edited');
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  submitEventCreation,
  editEvent,
};

export { thunks };
