import { format, parse } from 'date-fns';
import { appTimeFormat } from './const';

export const convertLocalTimeToUTC = (localTime) => {
  const localDate = parse(localTime, 'HH:mm', new Date());
  const hours = localDate.getUTCHours().toString().padStart(2, '0');
  const minutes = localDate.getUTCMinutes().toString().padStart(2, '0');
  const utcTime = `${hours}:${minutes}`;

  return utcTime;
};

export const formatLessonTime = (time) => {
  const parsedTime = parse(time, appTimeFormat.H_24_SECONDS, new Date());

  return format(parsedTime, appTimeFormat.AMPM_12H);
};

export const formatMilitaryToAMPM = (time) => {
  if (!time) return '';
  const parsedTime = parse(time, appTimeFormat.H_24, new Date());

  return format(parsedTime, appTimeFormat.AMPM_12H_UPPERCASE);
};

export const getTimeSlots = (startTime = '0:00', shiftFirst = false) => {
  const timeSlots = [];
  const [startHour, startMinutes] = startTime.split(':').map(Number);
  const start = startHour + (startMinutes === 30 ? 0.5 : 0);

  for (let i = start; i < 24; i += 0.5) {
    const hour = Math.floor(i);
    const minutes = i % 1 > 0 ? '30' : '00';
    const period = hour < 12 || hour === 24 ? 'AM' : 'PM';
    const formattedHour = hour % 12 || 12;
    const formattedLabel = `${formattedHour}:${minutes}${period}`;
    const formattedValue = `${hour.toString().padStart(2, '0')}:${minutes}`;
    timeSlots.push({
      label: formattedLabel,
      value: formattedValue,
    });
  }

  if (shiftFirst) {
    timeSlots.shift();
  }

  return timeSlots;
};
