import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { WEB_API_ROUTES } from 'api/api-routes';
import { handleServerErrors } from 'utils/server-errors';
import { showToast } from 'utils/showToast';

const getList = createAsyncThunk('events/getList', async () => {
  try {
    const response = await api.get(WEB_API_ROUTES.events.list);

    return response.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const sendEmail = createAsyncThunk(
  'events/sendEmail',
  async ({ subject, messageText, messageFor }) => {
    try {
      const response = await api.post(WEB_API_ROUTES.events.sendEmail, {
        messageFor,
        subject,
        messageText,
      });
      showToast.success('Message sent');

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const sendSms = createAsyncThunk(
  'events/sendSms',
  async ({ messageText, messageFor }) => {
    try {
      const response = await api.post(WEB_API_ROUTES.events.sendSms, {
        messageFor,
        messageText,
      });
      showToast.success('Message sent');

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getList,
  sendEmail,
  sendSms,
};

export { thunks };
