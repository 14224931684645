import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { selectors } from './selectors';
import { thunks } from './thunks';

const defaultValues = {
  title: '',
  progress_status: { label: '', value: '' },
  styles: [],
  styleSearch: '',
  level: { label: '', value: '' },
  types: [],
  typeSearch: '',
  teachers: [],
  teacherSearch: '',
  music_title: '',
  music_artist: '',
};

const initialState = {
  defaultRoutineInfo: defaultValues,
  styles: [],
  levels: [],
  types: [],
  statuses: [],
  submitCreationStatus: status.IDLE,
};

const slice = createSlice({
  name: 'createRoutine',
  initialState,
  reducers: {
    resetState(state) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getStyles.fulfilled, (state, { payload }) => {
        state.defaultRoutineInfo = defaultValues;
        state.styles = payload.data?.map((s) => ({
          ...s,
          value: s.id,
          label: s.name,
        }));
      })
      .addCase(thunks.getLevels.fulfilled, (state, { payload }) => {
        state.levels = payload.data?.map((l) => ({
          value: l,
          label: l,
        }));
      })
      .addCase(thunks.getTypes.fulfilled, (state, { payload }) => {
        state.types = payload.data?.map((t) => ({
          value: t,
          label: t,
        }));
      })
      .addCase(thunks.getStatuses.fulfilled, (state, { payload }) => {
        state.statuses = payload.data?.map((s) => ({
          value: s,
          label: s,
        }));
      })

      .addCase(thunks.submitCreation.pending, (state) => {
        state.submitCreationStatus = status.PENDING;
      })
      .addCase(thunks.submitCreation.fulfilled, (state) => {
        state.submitCreationStatus = status.SUCCESS;
      })
      .addCase(thunks.submitCreation.rejected, (state) => {
        state.submitCreationStatus = status.FAIL;
      });
  },
});

const createRoutine = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { createRoutine };
export default slice.reducer;
