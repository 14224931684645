import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { WEB_API_ROUTES } from 'api/api-routes';
import { handleServerErrors } from 'utils/server-errors';

const getStates = createAsyncThunk('general/getStates', async () => {
  try {
    const res = await api.get(WEB_API_ROUTES.general.getStates);

    return res.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const getCities = createAsyncThunk('general/getCities', async () => {
  try {
    const res = await api.get(WEB_API_ROUTES.general.getCities);

    return res.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const getLocations = createAsyncThunk('general/getLocations', async () => {
  try {
    const res = await api.get(WEB_API_ROUTES.general.locations);

    return res.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const getTeachers = createAsyncThunk('general/getTeachers', async () => {
  try {
    const res = await api.get(WEB_API_ROUTES.general.teachers);

    return res.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const search = createAsyncThunk(
  'general/search',
  async ({ query, category }) => {
    try {
      const res = await api.get(WEB_API_ROUTES.general.search, {
        params: { query, category },
      });

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getStates,
  getCities,
  getLocations,
  getTeachers,
  search,
};

export { thunks };
