import uuid from 'react-uuid';
import { createSlice } from '@reduxjs/toolkit';

import { relativesRoles, status } from 'utils/const';
import { dancerDefaultValuesModel } from 'models/dancerDefaultValuesModel';
import { relativeEditModel } from 'models/relativeEditModel';
import { createdRelativeResponseModel } from 'models/createdRelativeResponseModel';
import { getPerformanceDateTime } from 'utils/dates';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  dancerInfo: {},
  relationships: [],
  dancerUpcomingAppointments: [],
  dancerEvaluation: [],
  ratings: [],
  notifications: {
    list: [],
    currentPage: 1,
  },
  routines: {
    dancerRoutines: [],
    list: [],
    currentPage: 1,
  },
  performances: {
    dancerPerformances: [],
    currentPage: 1,
  },
  dancerClasses: [],
  availableClasses: [],
  loadingDancerInfoStatus: status.IDLE,
  loadingNotificationsStatus: status.IDLE,
  createGuardianStatus: status.IDLE,
  getEvaluationStatus: status.IDLE,
  loadingClassesStatus: status.IDLE,
  loadingAvailableClassesStatus: status.IDLE,
  loadingRatingStatus: status.IDLE,
  loadingPerformanceStatus: status.IDLE,
};

const slice = createSlice({
  name: 'dancerDetails',
  initialState,
  reducers: {
    resetState(state) {
      Object.assign(state, initialState);
    },
    resetStatus(state, { payload }) {
      state[payload] = status.IDLE;
    },
    setPage: (state, { payload }) => {
      state.notifications.currentPage = payload;
    },
    addGuardian(state) {
      state.relationships.push({ _id: uuid(), status: 'pending' });
    },
    updateGuardian(state, { payload }) {
      const guardianIndex = state.relationships.findIndex(
        (g) => g._id === payload._id
      );

      if (guardianIndex !== -1) {
        state.relationships[guardianIndex] = {
          ...state.relationships[guardianIndex],
          ...payload.data,
          status: 'saved',
        };
      }
    },
    removeGuardian(state, { payload }) {
      const guardianIndex = state.relationships.findIndex(
        (g) => g._id === payload
      );

      if (guardianIndex !== -1) {
        state.relationships.splice(guardianIndex, 1);
      }
    },
    setRoutinesPage: (state, { payload }) => {
      state.routines.currentPage = payload;
    },
    setPerformancesPage: (state, { payload }) => {
      state.performances.currentPage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getDancerInfo.pending, (state) => {
        state.loadingDancerInfoStatus = status.PENDING;
      })
      .addCase(thunks.getDancerInfo.fulfilled, (state, { payload }) => {
        state.dancerInfo = dancerDefaultValuesModel(payload.data);
        state.relationships = payload.data.relationships.map((r) => ({
          ...relativeEditModel({
            ...r,
            roles: relativesRoles,
            id: { value: r.id, label: r.full_name },
            _id: uuid(),
          }),
          status: 'saved',
        }));
        state.loadingDancerInfoStatus = status.SUCCESS;
      })
      .addCase(thunks.getDancerInfo.rejected, (state) => {
        state.loadingDancerInfoStatus = status.FAIL;
      })
      .addCase(
        thunks.getDancerUpcomingAppointments.fulfilled,
        (state, action) => {
          state.dancerUpcomingAppointments = action.payload.data;
        }
      )

      .addCase(thunks.createGuardian.pending, (state) => {
        state.createGuardianStatus = status.PENDING;
      })
      .addCase(thunks.createGuardian.fulfilled, (state, { payload }) => {
        const guardianIndex = state.relationships.findIndex(
          (g) => g._id === payload._id
        );
        if (guardianIndex !== -1) {
          state.relationships[guardianIndex] = {
            ...state.relationships[guardianIndex],
            ...createdRelativeResponseModel({
              ...payload.relative,
              roles: relativesRoles,
            }),
            status: 'saved',
          };
        }
        state.createGuardianStatus = status.SUCCESS;
      })
      .addCase(thunks.createGuardian.rejected, (state) => {
        state.createGuardianStatus = status.FAIL;
      })

      .addCase(thunks.getDancerEvaluationInfo.pending, (state) => {
        state.getEvaluationStatus = status.PENDING;
      })
      .addCase(
        thunks.getDancerEvaluationInfo.fulfilled,
        (state, { payload }) => {
          state.dancerEvaluation = payload.data;
          state.getEvaluationStatus = status.SUCCESS;
        }
      )
      .addCase(thunks.getDancerEvaluationInfo.rejected, (state) => {
        state.getEvaluationStatus = status.FAIL;
      })

      .addCase(thunks.getRatings.pending, (state) => {
        state.getRatings = status.PENDING;
      })
      .addCase(thunks.getRatings.fulfilled, (state, { payload }) => {
        state.ratings = payload.data;
        state.getRatings = status.SUCCESS;
      })
      .addCase(thunks.getRatings.rejected, (state) => {
        state.getRatings = status.FAIL;
      })

      .addCase(thunks.getPerformances.pending, (state) => {
        state.loadingPerformanceStatus = status.PENDING;
      })
      .addCase(thunks.getPerformances.fulfilled, (state, { payload }) => {
        state.loadingPerformanceStatus = status.SUCCESS;

        const preparedData = payload?.data?.map((p) => ({
          routine: p.routine_title,
          event: p.event_name,
          dateTime: getPerformanceDateTime(p.date, p.time_formatted),
          ...p,
        }));

        state.performances.dancerPerformances = preparedData || [];
      })

      .addCase(thunks.getNotifications.pending, (state) => {
        state.loadingNotificationsStatus = status.PENDING;
      })
      .addCase(thunks.getNotifications.fulfilled, (state, { payload }) => {
        state.notifications.list = payload.data;
        state.loadingNotificationsStatus = status.SUCCESS;
      })
      .addCase(thunks.getNotifications.rejected, (state) => {
        state.loadingNotificationsStatus = status.FAIL;
      })

      .addCase(thunks.getDancerClasses.pending, (state) => {
        state.loadingClassesStatus = status.PENDING;
      })
      .addCase(thunks.getDancerClasses.fulfilled, (state, { payload }) => {
        state.loadingClassesStatus = status.SUCCESS;
        state.dancerClasses = payload.data;
      })
      .addCase(thunks.getDancerClasses.rejected, (state) => {
        state.loadingClassesStatus = status.FAIL;
      })

      .addCase(thunks.getAvailableClasses.pending, (state) => {
        state.loadingAvailableClassesStatus = status.PENDING;
      })
      .addCase(thunks.getAvailableClasses.fulfilled, (state, { payload }) => {
        state.availableClasses = payload?.data;
        state.loadingAvailableClassesStatus = status.SUCCESS;
      })
      .addCase(thunks.getAvailableClasses.rejected, (state, { payload }) => {
        state.availableClasses = payload?.data;
        state.loadingAvailableClassesStatus = status.FAIL;
      })

      .addCase(thunks.getDancerRoutines.fulfilled, (state, { payload }) => {
        const preparedData = payload.data.map(
          ({ styles, teachers, types, ...rest }) => ({
            ...rest,
            types: types.join(', '),
            styles: styles.map((s) => s.name).join(', '),
            teachers: teachers.map((s) => s.full_name).join(', '),
          })
        );

        state.routines.dancerRoutines = preparedData;
      })
      .addCase(thunks.getRoutinesList.fulfilled, (state, { payload }) => {
        state.routines.list = payload.data;
      });
  },
});

const dancerDetails = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { dancerDetails };
export default slice.reducer;
