import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { WEB_API_ROUTES } from 'api/api-routes';
import { handleServerErrors } from 'utils/server-errors';

const getRoutinesList = createAsyncThunk(
  'routines/getRoutinesList',
  async () => {
    try {
      const response = await api.get(WEB_API_ROUTES.routines.list);

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getRoutinesList,
};

export { thunks };
