import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { selectors } from './selectors';
import { thunks } from './thunks';

const initialState = {
  data: null,
  submitEventCreationStatus: status.IDLE,
  editEventStatus: status.IDLE,
};

const slice = createSlice({
  name: 'createEvent',
  initialState,
  reducers: {
    resetState(state) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.submitEventCreation.pending, (state) => {
        state.submitEventCreationStatus = status.PENDING;
      })
      .addCase(thunks.submitEventCreation.fulfilled, (state) => {
        state.submitEventCreationStatus = status.SUCCESS;
      })
      .addCase(thunks.submitEventCreation.rejected, (state) => {
        state.submitEventCreationStatus = status.FAIL;
      })
      .addCase(thunks.editEvent.pending, (state) => {
        state.editEventStatus = status.PENDING;
      })
      .addCase(thunks.editEvent.fulfilled, (state) => {
        state.editEventStatus = status.SUCCESS;
      })
      .addCase(thunks.editEvent.rejected, (state) => {
        state.editEventStatus = status.FAIL;
      });
  },
});

const createEvent = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { createEvent };
export default slice.reducer;
