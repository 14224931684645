import uuid from 'react-uuid';
import { createSlice } from '@reduxjs/toolkit';

import { dancerRoles, status } from 'utils/const';
import { createdRelativeResponseModel } from 'models/createdRelativeResponseModel';
import { relativeModel } from 'models/relativeModel';
import { selectors } from './selectors';
import { thunks } from './thunks';

const defaultValues = {
  // is_primary_contact: false,
  first_name: '',
  last_name: '',
  phone: '',
  email: '',
  address_line_1: '',
  address_line_2: '',
  city: '',
  state: { label: '', value: '' },
  zipcode: '',
  country: { label: 'USA', value: 'USA' },
};

const initialState = {
  defaultParentInfo: defaultValues,
  dancers: [],
  relatives: [],
  createDancerStatus: status.IDLE,
  submitParentCreationStatus: status.IDLE,
};

const slice = createSlice({
  name: 'createParent',
  initialState,
  reducers: {
    resetState(state) {
      Object.assign(state, initialState);
    },
    addCreatedRelative(state, { payload }) {
      state.relatives.push(payload);
    },
    createPendingRelative(state) {
      state.dancers.push({ _id: uuid(), status: 'pending' });
    },
    updateRelative(state, { payload }) {
      const dancerIndex = state.dancers.findIndex((g) => g._id === payload._id);

      if (dancerIndex !== -1) {
        state.dancers[dancerIndex] = {
          ...state.dancers[dancerIndex],
          ...payload.data,
          status: 'saved',
        };
      }
    },
    removeRelative(state, { payload }) {
      const dancerIndex = state.dancers.findIndex((g) => g._id === payload);

      if (dancerIndex !== -1) {
        state.dancers.splice(dancerIndex, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.submitParentCreation.pending, (state) => {
        state.submitParentCreationStatus = status.PENDING;
      })
      .addCase(thunks.submitParentCreation.fulfilled, (state) => {
        state.submitParentCreationStatus = status.SUCCESS;
      })
      .addCase(thunks.submitParentCreation.rejected, (state) => {
        state.submitParentCreationStatus = status.FAIL;
      })

      .addCase(thunks.createRelative.pending, (state) => {
        state.createDancerStatus = status.PENDING;
      })
      .addCase(thunks.createRelative.fulfilled, (state, { payload }) => {
        const dancerIndex = state.dancers.findIndex(
          (d) => d._id === payload._id
        );

        if (dancerIndex !== -1) {
          state.dancers[dancerIndex] = {
            ...state.dancers[dancerIndex],
            ...createdRelativeResponseModel({
              ...payload.relative,
              roles: dancerRoles,
            }),
            status: 'saved',
          };
        }
        state.createDancerStatus = status.SUCCESS;
      })

      .addCase(thunks.createRelative.rejected, (state) => {
        state.createDancerStatus = status.FAIL;
      })

      .addCase(thunks.getRelatives.fulfilled, (state, { payload }) => {
        // TODO: flat on presentation level?
        state.relatives = Object.values(payload)
          .flat()
          .map((relative) => relativeModel(relative));
        state.fetchingRelativesStatus = status.SUCCESS;
      })
      .addCase(thunks.getRelatives.rejected, (state) => {
        state.fetchingRelativesStatus = status.FAIL;
      });
  },
});

const createParent = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { createParent };
export default slice.reducer;
