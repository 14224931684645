const selectors = {
  routinesList: (state) => state.routines.routinesList,
  filters: (state) => state.routines.filters,
  pagination: (state) => state.routines.pagination,
  preparedEntities: (state) => state.routines.preparedEntities,

  loadingListStatus: (state) => state.routines.loadingListStatus,
};

export { selectors };
