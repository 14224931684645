import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { pageTabs } from 'pages/User/Performance/PerformanceDetails/config';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  performanceInfo: {},
  currentTab: pageTabs[0].slug,
  dancers: {
    performanceDancers: [],
    list: [],
    currentPage: 1,
  },

  loadingPerformanceInfoStatus: status.IDLE,
};

const slice = createSlice({
  name: 'performanceDetails',
  initialState,
  reducers: {
    resetState(state) {
      Object.assign(state, initialState);
    },
    setPageTab(state, { payload }) {
      state.currentTab = payload;
    },
    setDancersPage: (state, { payload }) => {
      state.dancers.currentPage = payload;
    },
    setPerformancesPage: (state, { payload }) => {
      state.performances.currentPage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.gePerformanceInfo.pending, (state) => {
        state.loadingPerformanceInfoStatus = status.PENDING;
      })
      .addCase(thunks.gePerformanceInfo.fulfilled, (state, { payload }) => {
        state.performanceInfo = payload.data;
        state.loadingPerformanceInfoStatus = status.SUCCESS;
      })
      .addCase(thunks.gePerformanceInfo.rejected, (state) => {
        state.loadingPerformanceInfoStatus = status.FAIL;
      })

      .addCase(thunks.getDancersList.fulfilled, (state, { payload }) => {
        state.dancers.list = payload.data;
      })
      .addCase(thunks.getPerformanceDancers.fulfilled, (state, { payload }) => {
        state.dancers.performanceDancers = payload.data;
      });
  },
});

const performanceDetails = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { performanceDetails };
export default slice.reducer;
