import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { WEB_API_ROUTES } from 'api/api-routes';
import { handleServerErrors } from 'utils/server-errors';
import { showToast } from 'utils/showToast';

const getList = createAsyncThunk('classes/getList', async () => {
  try {
    const response = await api.get(WEB_API_ROUTES.classes.list);

    return response.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const sendEmail = createAsyncThunk(
  'classDetails/sendEmail',
  async ({ classId, subject, messageText }) => {
    try {
      const res = await api.post(
        WEB_API_ROUTES.classes.sendEmail.replace('{classId}', classId),
        { subject, messageText }
      );

      showToast.success('Message sent');
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const sendSms = createAsyncThunk(
  'classDetails/sendSms',
  async ({ classId, messageText }) => {
    try {
      const res = await api.post(
        WEB_API_ROUTES.classes.sendSms.replace('{classId}', classId),
        { messageText }
      );

      showToast.success('Message sent');
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getList,
  sendEmail,
  sendSms,
};

export { thunks };
