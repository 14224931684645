import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { WEB_API_ROUTES } from 'api/api-routes';
import { handleServerErrors } from 'utils/server-errors';
import { showToast } from 'utils/showToast';
import { createDancer } from 'store/createDancer';

const submitDancerCreation = createAsyncThunk(
  'createDancer/submitDancerCreation',
  async (data) => {
    try {
      const res = await api.post(WEB_API_ROUTES.dancers.createDancer, data);
      showToast.success('New dancer created');
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const createGuardian = createAsyncThunk(
  'createDancer/createGuardian',
  async ({ data, _id }, { dispatch }) => {
    try {
      const { data: guardianData } = await api.post(
        WEB_API_ROUTES.dancers.createRelative,
        data
      );

      const newRelativeId = {
        value: guardianData.data.relative.id,
        label: `${guardianData.data.relative.first_name} ${guardianData.data.relative.last_name}`,
      };

      dispatch(createDancer.actions.addRelative(newRelativeId));

      showToast.success('Parent Saved');

      return {
        relative: {
          ...guardianData.data.relative,
          id: newRelativeId,
          is_primary_contact: data.is_primary_contact,
        },
        _id,
      };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const removeDancerAvatar = createAsyncThunk(
  'createDancer/removeDancerAvatar',
  async (id) => {
    try {
      const res = await api.get(
        WEB_API_ROUTES.dancers.removeDancerAvatar.replace('{dancer_id}', id)
      );
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getRelatives = createAsyncThunk('createDancer/getRelatives', async () => {
  try {
    const res = await api.get(WEB_API_ROUTES.dancers.getRelatives);

    return res.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const thunks = {
  submitDancerCreation,
  createGuardian,
  removeDancerAvatar,
  getRelatives,
};

export { thunks };
