export const relativeEditModel = ({ _id, id, role, pivot, roles }) => {
  const preparedRole = roles.find((r) => r.label === role);

  return {
    _id,
    id,
    role: preparedRole || { value: 0, label: 'Role not selected' },
    is_primary_contact: pivot.is_primary_contact,
  };
};
