import { createSlice } from '@reduxjs/toolkit';

import { thunks } from './thunks';
import { selectors } from './selectors';

const defaultValues = {
  role: { label: '', value: '' },
  parentName: { label: '', value: '' },
  dancerName: { label: '', value: '' },
};

const initialState = {
  list: [],
  preparedEntities: {
    parentList: [],
    dancerList: [],
  },
  filters: {
    fields: defaultValues,
    updatedFields: 0,
  },
  pagination: {
    current: 1,
  },
};

const slice = createSlice({
  name: 'parents',
  initialState,
  reducers: {
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
    setFilters: (state, { payload }) => {
      let updatedFieldsCount = 0;
      const defaultValues = Object.values(initialState.filters.fields);

      Object.values(payload).forEach((field, index) => {
        if (field.label !== defaultValues[index].label) {
          updatedFieldsCount += 1;
        }
      });

      state.filters.updatedFields = updatedFieldsCount;
      state.filters.fields = payload;
    },
    setPage: (state, { payload }) => {
      state.pagination.current = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunks.getParentsList.fulfilled, (state, { payload }) => {
      const uniqueDancerNames = new Set();

      const preparedFilters = payload.data.reduce(
        (acc, { id, name, child }) => {
          acc.parentList.push({ value: id, label: name });

          child.forEach(({ id, full_name }) => {
            if (!uniqueDancerNames.has(full_name)) {
              uniqueDancerNames.add(full_name);
              acc.dancerList.push({ value: id, label: full_name });
            }
          });

          return acc;
        },
        { parentList: [], dancerList: [] }
      );

      state.preparedEntities.parentList = preparedFilters.parentList;
      state.preparedEntities.dancerList = preparedFilters.dancerList;

      state.list = payload.data;
    });
  },
});

const parents = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { parents };
export default slice.reducer;
