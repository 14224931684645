import { createSlice } from '@reduxjs/toolkit';

import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  token: null,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUnauthorized() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.logIn.fulfilled, (state, action) => {
        state.token = action.payload.data.accessToken;
      })
      .addCase(thunks.signUp.fulfilled, (state, action) => {
        state.token = action.payload.data.accessToken;
      })
      .addCase(thunks.logout.fulfilled, (state) => {
        Object.assign(state, initialState);
      })
      .addCase(thunks.googleLogin.fulfilled, (state, action) => {
        const { accessToken } = action.payload.data;
        state.token = accessToken;
      })
      .addCase(thunks.googleSignUp.fulfilled, (state, action) => {
        const { accessToken } = action.payload.data;
        state.token = accessToken;
      })
      .addCase(thunks.appleLogin.fulfilled, (state, action) => {
        const { accessToken } = action.payload.data;
        state.token = accessToken;
      })
      .addCase(thunks.appleSignUp.fulfilled, (state, action) => {
        const { accessToken } = action.payload.data;
        state.token = accessToken;
      });
  },
});

const auth = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { auth };
export default slice.reducer;
