const status = {
  IDLE: 'idle',
  PENDING: 'pending',
  SUCCESS: 'success',
  FAIL: 'fail',
};

const thunkStatus = {
  PENDING: 'pending',
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
};

const appDateFormat = {
  DMY: 'dd.MM.yyyy',
  MDY: 'MM.dd.yyyy',
  DMY_DASH: 'dd-MM-yyyy',
  D_M_SHORT_Y: 'dd MMM yyyy',
  M_FULL_D_Y: 'MMMM dd, yyyy',
  D_M_FULL_Y_W_FULL: 'dd MMMM yyyy, EEEE',
  M_FULL_D_FULL_Y: 'EEEE, MMMM dd',
  FULL_Y_M_D: 'yyyy-MM-dd',
  M_FULL_D_W_FULL: 'MMMM d, eeee',
  FULL_DATE_TIME: 'MMM do, yyyy h:mma',
  W_SHORT_M_SHORT_D: 'EEE, MMM d',
  M_SHORT_D_Y: 'MMM d, yyyy',
  M_FULL_D: 'd MMMM',
  M_FULL_D_ORDERED_Y_FULL: 'MMMM, do yyyy',
  M_SHORT_D_ORDERED_Y_FULL: 'MMM, do yyyy',
  M_SHORT_D_FULL_Y_TIME: 'MMM dd, yyyy; HH:mm',
  M_SHORT_D_FULL_Y: 'MMM dd, yyyy',
};

const appTimeFormat = {
  AMPM_12H: 'h:mm a',
  AMPM_12H_UPPERCASE: 'h:mma',
  H_12: 'h:mm',
  H_24: 'HH:mm',
  H_24_SECONDS: 'HH:mm:ss',
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const schedulerViews = {
  DAY: 'resourceTimeGridDay',
  THREE_DAYS: 'timeGridThreeDays',
  WEEK: 'timeGridWeek',
};

const relativesRoles = [
  { value: 1, label: 'Mom' },
  { value: 2, label: 'Dad' },
  { value: 3, label: 'Babysitter' },
  { value: 4, label: 'Grandfather' },
  { value: 5, label: 'Grandmother' },
  { value: 6, label: 'Other Guardian' },
];

const dancerRoles = [
  { value: 1, label: 'Daughter' },
  { value: 2, label: 'Son' },
  { value: 3, label: 'Set babysitter by' },
  { value: 4, label: 'Granddaughter' },
  { value: 5, label: 'Grandson' },
];

const appointmentPopupModes = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  READ_ONLY: 'READ_ONLY',
};

const classesStyles = [
  { label: 'Ballet', value: 1 },
  { label: 'Hip Hop', value: 2 },
  { label: 'Open', value: 3 },
  { label: 'Modern', value: 4 },
  { label: 'Tap', value: 5 },
  { label: 'Ballroom', value: 6 },
  { label: 'Contemporary', value: 7 },
  { label: 'Folk', value: 8 },
  { label: 'Acrobatic Arts', value: 9 },
  { label: 'Jazz', value: 10 },
  { label: 'Lyrical', value: 11 },
  { label: 'Theater', value: 12 },
  { label: 'Custom', value: 13 },
];

const levels = [
  { label: 'Level 1', value: 1 },
  { label: 'Level 2', value: 2 },
  { label: 'Level 3', value: 3 },
  { label: 'Level 4', value: 4 },
  { label: 'Level 5', value: 5 },
  { label: 'Level 6', value: 6 },
  { label: 'Level 7', value: 7 },
  { label: 'Level 8', value: 8 },
  { label: 'Level 9', value: 9 },
  { label: 'Level 10', value: 10 },
  { label: 'Level 11', value: 11 },
  { label: 'Level 12', value: 12 },
  { label: 'Level 13', value: 13 },
  { label: 'Level 14', value: 14 },
  { label: 'Level 15', value: 15 },
];

const routineTypes = [
  { label: 'Large Group', value: 1 },
  { label: 'Line', value: 2 },
  { label: 'Solo', value: 3 },
  { label: 'Trio', value: 4 },
  { label: 'Small Group', value: 5 },
];

const routineStatuses = [
  { label: 'Planning', value: 1 },
  { label: 'Choreography', value: 2 },
  { label: 'Learning', value: 3 },
  { label: 'Cleaning', value: 4 },
  { label: 'Ready for Performance', value: 5 },
  { label: 'Archived', value: 6 },
];

const weekDays = [
  { value: 'Sunday', label: 'Sunday' },
  { value: 'Monday', label: 'Monday' },
  { value: 'Tuesday', label: 'Tuesday' },
  { value: 'Wednesday', label: 'Wednesday' },
  { value: 'Thursday', label: 'Thursday' },
  { value: 'Friday', label: 'Friday' },
  { value: 'Saturday', label: 'Saturday' },
];

export {
  status,
  thunkStatus,
  appDateFormat,
  appTimeFormat,
  months,
  schedulerViews,
  relativesRoles,
  dancerRoles,
  appointmentPopupModes,
  classesStyles,
  levels,
  weekDays,
  routineTypes,
  routineStatuses,
};
