import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

import { useScrollToTop } from 'hooks/useScrollToTop';
import { NotFoundPage } from 'pages/General/NotFound';
import { APP_ROUTES } from 'router/appRoutes';

const AuthLayout = lazy(() => import('layouts/AuthLayout'));
const UserLayout = lazy(() => import('layouts/UserLayout'));

function App() {
  useScrollToTop();

  return (
    <Suspense fallback={<>Loading</>}>
      <Routes>
        <Route
          path={`${APP_ROUTES.user.index}`}
          element={<UserLayout />}
        />
        <Route
          path={`${APP_ROUTES.auth.index}`}
          element={<AuthLayout />}
        />
        <Route
          path="*"
          element={<NotFoundPage />}
        />
      </Routes>
    </Suspense>
  );
}

export default App;
