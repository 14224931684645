import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { selectors } from './selectors';
import { thunks } from './thunks';

const defaultValues = {
  dancers: [],
  dancerSearch: '',
  date: null,
  time: { label: '', value: '' },
  event_id: { label: '', value: '' },
  routine_id: { label: '', value: '' },
  entryId: '',
  score: '',
  isRoutine: false,
};

const initialState = {
  defaultPerformanceInfo: defaultValues,
  dancers: [],
  objectForCreatePerformance: {
    id: '',
    name: '',
    dancers: [],
    objectList: [],
    isRoutine: false,
  },
  submitCreationStatus: status.IDLE,
};

const slice = createSlice({
  name: 'createPerformance',
  initialState,
  reducers: {
    resetState(state) {
      Object.assign(state, initialState);
    },
    setobjectForCreatePerformance(state, { payload }) {
      state.objectForCreatePerformance = payload;
      state.defaultPerformanceInfo = {
        ...defaultValues,
        isRoutine: payload.isRoutine,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        thunks.getRoutinePerformanceDancers.fulfilled,
        (state, { payload }) => {
          // state.defaultPerformanceInfo = defaultValues;
          state.dancers = payload.data?.map((s) => ({
            ...s,
            value: s.id,
            label: s.name,
          }));
        }
      )

      .addCase(thunks.submitCreation.pending, (state) => {
        state.submitCreationStatus = status.PENDING;
      })
      .addCase(thunks.submitCreation.fulfilled, (state) => {
        state.submitCreationStatus = status.SUCCESS;
      })
      .addCase(thunks.submitCreation.rejected, (state) => {
        state.submitCreationStatus = status.FAIL;
      });
  },
});

const createPerformance = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { createPerformance };
export default slice.reducer;
