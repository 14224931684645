import { createSlice } from '@reduxjs/toolkit';

import { thunks } from './thunks';
import { selectors } from './selectors';

const defaultValues = {
  ageFrom: null,
  ageTo: null,
  birthdateFrom: null,
  birthdateTo: null,
  city: '',
};

const initialState = {
  dancersList: [],
  filters: {
    fields: defaultValues,
    updatedFields: 0,
  },
  pagination: {
    current: 1,
  },
};

const slice = createSlice({
  name: 'dancers',
  initialState,
  reducers: {
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
    setFilters: (state, { payload }) => {
      let updatedFieldsCount = 0;
      const defaultValues = Object.values(initialState.filters.fields);

      Object.values(payload).forEach((field, index) => {
        if (field !== defaultValues[index]) {
          updatedFieldsCount += 1;
        }
      });

      state.filters.updatedFields = updatedFieldsCount;
      state.filters.fields = payload;
    },
    setPage: (state, { payload }) => {
      state.pagination.current = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunks.getDancersList.fulfilled, (state, action) => {
      state.dancersList = action.payload.data;
    });
  },
});

const dancers = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { dancers };
export default slice.reducer;
