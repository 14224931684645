const prepareSelectField = (options = []) => {
  if (!Array.isArray(options)) return [];

  return options.map((option) => ({
    value: option,
    label: option,
  }));
};

const isFieldWithObjValueFilled = (value) => {
  return !value?.label ? null : value;
};

const prepareFormForSend = (data) => {
  Object.keys(data).forEach((key) => {
    const value = data[key];
    if (typeof value === 'object' && value !== null && 'value' in value) {
      data[key] = value.value;
    }
  });

  return data;
};

export { prepareSelectField, isFieldWithObjValueFilled, prepareFormForSend };
