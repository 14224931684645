export const colors = {
  gray25: '#FFFFFF',
  gray50: '#F4F3F9',
  gray75: '#EDECF2',
  gray90: '#DEDCE7',
  gray100: '#C1BECF',
  gray200: '#9D9DA1',
  gray300: '#7E7B8F',
  gray400: '#676770',
  gray500: '#676770',
  gray600: '#4F5163',
  gray950: '#090A14',
  brand50: '#F2EFFF',
  brand100: '#E2DCFF',
  brand300: '#7A7AE4',
  brand400: '#34079F',
  brand500: '#250473',
  brand600: '#3F22D3',
  brand700: '#1B0354',
  red100: '#FFE7E7',
  red500: '#D84B4B',
  green25: '#F6FFF7',
  green100: '#E3FFE4',
  green400: '#63D770',
  green500: '#42CD47',
  purple100: '#E8DDFF',
};
