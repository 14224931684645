export const dancerDefaultValuesModel = ({
  id,
  address,
  birthdate,
  birthdate_unix,
  first_name,
  last_name,
  email,
  phone,
  photo,
  relationships,
  measurements,
}) => {
  return {
    id,
    birthdate: birthdate_unix || null,
    birthdatePresentation: birthdate || null,
    first_name: first_name || '',
    last_name: last_name || '',
    email: email || '',
    phone: phone || '',
    measurements: {
      ...measurements,
      last_updated_presentation: measurements.last_updated,
      last_updated: measurements.last_updated_unix,
    },
    relationships,
    image: photo || null,
    fullAddress: address.summary,
    city: address.city || '',
    country: { label: 'USA', value: 'USA' },
    state: address.state || { label: '', value: '' },
    address_line_1: address.address_line_1 || '',
    address_line_2: address.address_line_2 || '',
    zipcode: address.zipcode || '',
  };
};
