import unassignedAvatar from 'assets/img/Avatar.png';

export const resourceTypes = {
  teacher: 'teacher',
  location: 'location',
};

export const eventTypes = {
  private: 'private',
  group: 'group classes',
  internal: 'internal',
  request: 'request',
  unavailable: 'unavailable',
};

export const unassignedTeacher = {
  id: -1,
  first_name: 'Unassigned',
  last_name: '',
  full_name: 'Unassigned',
  image: unassignedAvatar,
};

export const unassignedLocation = {
  id: -1,
  formattedLocation: 'Unassigned',
  name: 'Unassigned',
};

export const eventColors = {
  [eventTypes.group]: '#34312D',
  [eventTypes.private]: '#CA3864',
  [eventTypes.internal]: '#745FF6',
  [eventTypes.request]: '#FFFFFF',
  [eventTypes.unavailable]: '#7E7B8F',
};
