import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { WEB_API_ROUTES } from 'api/api-routes';
import { handleServerErrors } from 'utils/server-errors';

const gePerformanceInfo = createAsyncThunk(
  'performanceDetails/gePerformanceInfo',
  async (performanceId) => {
    try {
      const res = await api.get(
        WEB_API_ROUTES.performances.performanceInfo.replace(
          '{performanceId}',
          performanceId
        )
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updatePerformanceInfo = createAsyncThunk(
  'performanceDetails/updatePerformanceInfo',
  async ({ performanceId, data }) => {
    try {
      const res = await api.put(
        WEB_API_ROUTES.performances.updatePerformance.replace(
          '{performanceId}',
          performanceId
        ),
        data
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getDancersList = createAsyncThunk(
  'performanceDetails/getDancersList',
  async ({ routineId, performanceId }) => {
    try {
      const res = await api.get(
        WEB_API_ROUTES.performances.dancersList
          .replace('{routineId}', routineId)
          .replace('{performanceId}', performanceId)
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getPerformanceDancers = createAsyncThunk(
  'performanceDetails/getPerformanceDancers',
  async (performanceId) => {
    try {
      const res = await api.get(
        WEB_API_ROUTES.performances.performanceDancers.replace(
          '{performanceId}',
          performanceId
        )
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const deletePerformance = createAsyncThunk(
  'performanceDetails/deletePerformance',
  async (performanceId) => {
    try {
      const res = await api.delete(
        WEB_API_ROUTES.performances.delete.replace(
          '{performanceId}',
          performanceId
        )
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updatePerformanceDancers = createAsyncThunk(
  'performanceDetails/updatePerformanceDancers',
  async ({ performanceId, dancers }) => {
    try {
      const res = await api.put(
        WEB_API_ROUTES.performances.performanceDancers.replace(
          '{performanceId}',
          performanceId
        ),
        { dancers }
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  gePerformanceInfo,
  updatePerformanceInfo,
  getDancersList,
  getPerformanceDancers,
  deletePerformance,
  updatePerformanceDancers,
};

export { thunks };
