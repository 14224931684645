const selectors = {
  performanceInfo: (state) => state.performanceDetails.performanceInfo,
  currentTab: (state) => state.performanceDetails.currentTab,

  dancers: {
    performanceDancers: (state) =>
      state.performanceDetails.dancers.performanceDancers,
    list: (state) => state.performanceDetails.dancers.list,
    currentPage: (state) => state.performanceDetails.dancers.currentPage,
  },

  loadingPerformanceInfoStatus: (state) =>
    state.performanceDetails.loadingPerformanceInfoStatus,
};

export { selectors };
