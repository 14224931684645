const dancers = (state) => {
  const pending = state.createParent.dancers.filter(
    (g) => g.status === 'pending'
  );
  const saved = state.createParent.dancers.filter((g) => g.status === 'saved');

  return { pending, saved };
};
const selectors = {
  dancers,
  relatives: (state) => state.createParent.relatives,
  defaultParentInfo: (state) => state.createParent.defaultParentInfo,
  createDancerStatus: (state) => state.createParent.createDancerStatus,
  fetchingRelativesStatus: (state) =>
    state.createParent.fetchingRelativesStatus,
  submitParentCreationStatus: (state) =>
    state.createParent.submitParentCreationStatus,
};

export { selectors };
