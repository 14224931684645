import yup from 'plugins/yup-config';

const transactionTypes = {
  custom: 'Custom',
  startDate: 'Start Date',
  endDate: 'End Date',
  transactionDate: 'Transaction Date',
};

const defaultValues = {
  registration_active: false,
  payment_terms: '',
  payment_dates: [
    {
      due_date_type: transactionTypes.transactionDate,
      due_date: null,
      amount: '',
    },
  ],
};
const schema = yup.object().shape({
  registration_active: yup.boolean(),
  payment_terms: yup.string(),
  payment_dates: yup.array().of(
    yup.object().shape({
      due_date_type: yup.string().required(),
      due_date: yup
        .number()
        .nullable()
        .when('due_date_type', {
          is: transactionTypes.custom,
          then: (schema) => schema.required(),
          otherwise: (schema) => schema,
        }),
      amount: yup.string().required(),
    })
  ),
});

export { schema, defaultValues, transactionTypes };
