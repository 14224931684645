import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';

const defaultValues = {
  name: '',
  status: '',
  date_start: null,
  date_end: null,
};

const initialState = {
  list: [],
  filters: {
    fields: defaultValues,
    updatedFields: 0,
  },
  pagination: {
    current: 1,
  },

  loadingListStatus: status.IDLE,
};

const slice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
    setFilters: (state, { payload }) => {
      let updatedFieldsCount = 0;
      const defaultValues = Object.values(initialState.filters.fields);

      Object.values(payload).forEach((field, index) => {
        if (field !== defaultValues[index]) {
          updatedFieldsCount += 1;
        }
      });

      state.filters.updatedFields = updatedFieldsCount;
      state.filters.fields = payload;
    },
    setPage: (state, { payload }) => {
      state.pagination.current = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getList.pending, (state) => {
        state.loadingListStatus = status.PENDING;
      })
      .addCase(thunks.getList.fulfilled, (state, { payload }) => {
        state.loadingListStatus = status.SUCCESS;
        state.list = payload.data;
      })
      .addCase(thunks.getList.rejected, (state) => {
        state.loadingListStatus = status.FAIL;
      });
  },
});

const events = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { events };
export default slice.reducer;
