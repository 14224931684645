import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { WEB_API_ROUTES } from 'api/api-routes';
import { handleServerErrors } from 'utils/server-errors';
import { toast } from 'react-toastify';

const getRoutineInfo = createAsyncThunk(
  'routineDetails/getRoutineInfo',
  async ({ routineId }) => {
    try {
      const res = await api.get(
        WEB_API_ROUTES.routines.routineInfo.replace('{routineId}', routineId)
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updateRoutineInfo = createAsyncThunk(
  'routineDetails/updateRoutineInfo',
  async ({ routineId, data }) => {
    try {
      const res = await api.put(
        WEB_API_ROUTES.routines.editRoutine.replace('{routineId}', routineId),
        data
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const uploadAudio = createAsyncThunk(
  'routineDetails/uploadAudio',
  async ({ routineId, formData }) => {
    try {
      const res = await api.post(
        WEB_API_ROUTES.routines.uploadAudio.replace('{routineId}', routineId),
        formData
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const removeAudio = createAsyncThunk(
  'routineDetails/removeAudio',
  async (routineId) => {
    try {
      const res = await api.get(
        WEB_API_ROUTES.routines.removeAudio.replace('{routineId}', routineId)
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getDancersList = createAsyncThunk(
  'routineDetails/getDancersList',
  async (routineId) => {
    try {
      const res = await api.get(
        WEB_API_ROUTES.routines.dancersList.replace('{routineId}', routineId)
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getRoutineDancers = createAsyncThunk(
  'routineDetails/getRoutineDancers',
  async (routineId) => {
    try {
      const res = await api.get(
        WEB_API_ROUTES.routines.routineDancers.replace('{routineId}', routineId)
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const addDancerToRoutine = createAsyncThunk(
  'routineDetails/addDancerToRoutine',
  async ({ routineId, dancers }) => {
    try {
      const res = await api.put(
        WEB_API_ROUTES.routines.routineDancers.replace(
          '{routineId}',
          routineId
        ),
        { dancers }
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const deleteRoutine = createAsyncThunk(
  'routineDetails/deleteRoutine',
  async (routineId) => {
    try {
      const res = await api.delete(
        WEB_API_ROUTES.routines.deleteRoutine.replace('{routineId}', routineId)
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getRecipients = createAsyncThunk(
  'routineDetails/getRecipients',
  async (classId) => {
    try {
      const res = await api.get(
        WEB_API_ROUTES.classes.recipients.replace('{classId}', classId)
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const sendEmail = createAsyncThunk(
  'routineDetails/sendEmail',
  async ({ classId, subject, messageText }) => {
    try {
      const res = await api.post(
        WEB_API_ROUTES.classes.sendEmail.replace('{classId}', classId),
        { subject, messageText }
      );

      toast.success('Message sent');
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const sendSms = createAsyncThunk(
  'routineDetails/sendSms',
  async ({ classId, messageText }) => {
    try {
      const res = await api.post(
        WEB_API_ROUTES.classes.sendSms.replace('{classId}', classId),
        { messageText }
      );

      toast.success('Message sent');
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getRoutinePerformances = createAsyncThunk(
  'routineDetails/getRoutinePerformances',
  async (routineId) => {
    try {
      const res = await api.get(
        WEB_API_ROUTES.routines.routinePerformances.replace(
          '{routineId}',
          routineId
        )
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getRoutineInfo,
  updateRoutineInfo,
  uploadAudio,
  removeAudio,

  getDancersList,
  getRoutineDancers,
  addDancerToRoutine,
  deleteRoutine,
  getRecipients,
  sendEmail,
  sendSms,
  getRoutinePerformances,
};

export { thunks };
