import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { WEB_API_ROUTES } from 'api/api-routes';
import { handleServerErrors } from 'utils/server-errors';
import { showToast } from 'utils/showToast';

const getDancersList = createAsyncThunk('dancers/logIn', async () => {
  try {
    const response = await api.get(WEB_API_ROUTES.dancers.dancersList);

    return response.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const sendEmail = createAsyncThunk(
  'dancers/sendEmail',
  async ({ subject, messageText, messageFor }) => {
    try {
      const response = await api.post(WEB_API_ROUTES.dancers.sendEmail, {
        messageFor,
        subject,
        messageText,
      });
      showToast.success('Message sent');

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const sendSms = createAsyncThunk(
  'dancers/sendSms',
  async ({ messageText, messageFor }) => {
    try {
      const response = await api.post(WEB_API_ROUTES.dancers.sendSms, {
        messageFor,
        messageText,
      });
      showToast.success('Message sent');

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getDancersList,
  sendEmail,
  sendSms,
};

export { thunks };
