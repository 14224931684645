import storage from 'redux-persist/lib/storage';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

// REDUCERS
import auth from 'store/auth';
import registration from 'store/registration';
import dancers from 'store/dancers';
import parents from 'store/parents';
import scheduler from 'store/scheduler';
import general from 'store/general';
import createDancer from 'store/createDancer';
import dancerDetails from 'store/dancerDetails';
import createParent from 'store/createParent';
import parentDetails from 'store/parentDetails';
import classes from 'store/classes';
import createClass from 'store/createClass';
import classDetails from 'store/classDetails';
import routines from 'store/routines';
import createRoutine from 'store/createRoutine';
import routineDetails from 'store/routineDetails';
import events from 'store/events';
import createEvent from 'store/createEvent';
import eventDetails from 'store/eventDetails';
import createPerformance from 'store/createPerformance';
import performanceDetails from 'store/performanceDetails';

const rootReducer = combineReducers({
  auth,
  registration,
  dancers,
  parents,
  scheduler,
  general,
  createDancer,
  dancerDetails,
  createParent,
  parentDetails,
  classes,
  createClass,
  classDetails,
  routines,
  createRoutine,
  routineDetails,
  events,
  createEvent,
  eventDetails,
  createPerformance,
  performanceDetails,
});

const persistConfig = {
  key: 'studio manager',
  storage,
  blacklist: [],
  whitelist: [
    'auth',
    'registration',
    'general',
    'createDancer',
    'createParent',
    'createClass',
    'createRoutine',
    // 'createEvent', need to add ???
    'createPerformance',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export default store;
