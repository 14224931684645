import { formatMilitaryToAMPM } from '../utils/times';

export const scheduleDefaultValuesModel = ({
  classroom_id,
  start_timestamp,
  end_timestamp,
  weekly_schedule,
  is_exclude_closures,
}) => {
  const recurring = Object.values(weekly_schedule).map(
    ({ day, start, end }) => {
      return {
        dayOfWeek: { label: day, value: day },
        startTime: { label: formatMilitaryToAMPM(start), value: start },
        endTime: { label: formatMilitaryToAMPM(end), value: end },
      };
    }
  );

  return {
    classRoomId: classroom_id,
    startDate: start_timestamp,
    endDate: end_timestamp,
    recurring,
    excludeClosures: is_exclude_closures,
  };
};
