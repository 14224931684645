const selectors = {
  routineInfo: (state) => state.routineDetails.routineInfo,
  currentTab: (state) => state.routineDetails.currentTab,
  audioUrl: (state) => state.routineDetails.audioUrl,

  dancers: {
    routineDancers: (state) => state.routineDetails.dancers.routineDancers,
    list: (state) => state.routineDetails.dancers.list,
    currentPage: (state) => state.routineDetails.dancers.currentPage,
  },

  performances: {
    routinePerformances: (state) =>
      state.routineDetails.performances.routinePerformances,
    currentPage: (state) => state.routineDetails.performances.currentPage,
  },

  loadingRoutineInfoStatus: (state) =>
    state.routineDetails.loadingRoutineInfoStatus,
  routineDeletingStatus: (state) => state.routineDetails.routineDeletingStatus,

  recipients: (state) => state.classDetails.recipients,
};

export { selectors };
