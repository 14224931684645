const selectors = {
  eventInfo: (state) => state.eventDetails.eventInfo,
  activeTab: (state) => state.eventDetails.activeTab,

  performances: {
    eventPerformances: (state) =>
      state.eventDetails.performances.eventPerformances,
    currentPage: (state) => state.eventDetails.performances.currentPage,
  },

  eventLoadingStatus: (state) => state.eventDetails.eventLoadingStatus,
};

export { selectors };
