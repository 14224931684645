import styled from 'styled-components';

import { Block } from '../mixins';

const Typography = styled.div`
  ${Block};
  font-size: ${({ fz = '14px' }) => fz};
  font-weight: ${({ fw = 400 }) => fw};
  font-style: ${({ fs = 'normal' }) => fs};
  text-transform: ${({ tt = 'none' }) => tt};
  text-decoration: ${({ td = 'none' }) => td};
  text-align: ${({ ta = 'left' }) => ta};
  color: ${({ color, theme }) => color || theme.gray950};
  letter-spacing: ${({ ls = 'inherit' }) => ls};
  line-height: ${({ lh = '145%' }) => lh};
`;

const Heading = styled(Typography)``;

export { Typography, Heading };
