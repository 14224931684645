import { transactionTypes } from 'pages/User/Class/ClassDetails/PricingTab/CreatePricingForm/config';

const getDueDateTypePriority = (dueDateType) => {
  switch (dueDateType) {
    case 'Transaction Date':
      return 1;
    case 'Start Date':
      return 2;
    case 'Custom':
      return 3;
    default:
      return 4;
  }
};

export const sortPaymentDates = (array) => {
  return array.sort((a, b) => {
    const aPriority = getDueDateTypePriority(a.due_date_type);
    const bPriority = getDueDateTypePriority(b.due_date_type);

    if (aPriority === bPriority && aPriority === 3) {
      // If both due_date_type are "Custom", sort by due_date
      if (a.due_date === null) return 1; // a has lower priority
      if (b.due_date === null) return -1; // b has lower priority

      return a.due_date - b.due_date;
    }

    return aPriority - bPriority;
  });
};

export const pricingDefaultValuesModel = ({
  id,
  is_active,
  registration_active,
  payment_terms,
  payment_dates,
}) => {
  const preparedPaymentDates = sortPaymentDates(
    payment_dates.map(({ due_date_timestamp, due_date_type, amount, id }) => ({
      id,
      due_date_type,
      due_date:
        due_date_type === transactionTypes.custom ? due_date_timestamp : null,
      amount,
    }))
  );

  return {
    id,
    is_active,
    registration_active,
    payment_terms: payment_terms || '',
    payment_dates: preparedPaymentDates,
  };
};
