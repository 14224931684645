import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { WEB_API_ROUTES } from 'api/api-routes';
import { handleServerErrors } from 'utils/server-errors';
import { showToast } from 'utils/showToast';
import { createParent } from 'store/createParent';

const submitParentCreation = createAsyncThunk(
  'createParent/submitParentCreation',
  async (data) => {
    try {
      const res = await api.post(WEB_API_ROUTES.parents.createParent, data);
      showToast.success('New parent created');
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const createRelative = createAsyncThunk(
  'createParent/createRelative',
  async ({ data, _id }, { dispatch }) => {
    try {
      const { data: relativeData } = await api.post(
        WEB_API_ROUTES.parents.createRelative,
        data
      );

      const newRelativeId = {
        value: relativeData.data.relative.id,
        label: `${relativeData.data.relative.first_name} ${relativeData.data.relative.last_name}`,
      };

      dispatch(createParent.actions.addCreatedRelative(newRelativeId));

      showToast.success('Child Saved');

      return {
        relative: {
          ...relativeData.data.relative,
          id: newRelativeId,
          is_primary_contact: data.is_primary_contact,
        },
        _id,
      };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getRelatives = createAsyncThunk('createParent/getRelatives', async () => {
  try {
    const res = await api.get(WEB_API_ROUTES.parents.getRelatives);

    return res.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});
const thunks = {
  submitParentCreation,
  createRelative,
  getRelatives,
};

export { thunks };
