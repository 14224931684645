export const parentDefaultValuesModel = ({
  id,
  address,
  first_name,
  last_name,
  email,
  phone,
  other_guardians,
}) => {
  return {
    id,
    first_name: first_name || '',
    last_name: last_name || '',
    email: email || '',
    phone: phone || '',
    relationships: other_guardians || [],
    fullAddress: address.summary,
    city: address.city || '',
    country: { label: 'USA', value: 'USA' },
    state: address.state || { label: '', value: '' },
    address_line_1: address.address_line_1 || '',
    address_line_2: address.address_line_2 || '',
    zipcode: address.zipcode || '',
  };
};
