import uuid from 'react-uuid';
import { createSlice } from '@reduxjs/toolkit';

import { dancerRoles, relativesRoles, status } from 'utils/const';
import { relativeEditModel } from 'models/relativeEditModel';
import { createdRelativeResponseModel } from 'models/createdRelativeResponseModel';
import { parentDefaultValuesModel } from 'models/parentDefaultValuesModel';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  parentInfo: {},
  relationships: [],
  dancers: [],
  editDancers: [],
  parentClasses: [],
  notifications: {
    list: [],
    currentPage: 1,
  },
  loadingParentInfoStatus: status.IDLE,
  loadingDancersStatus: status.IDLE,
  loadingNotificationsStatus: status.IDLE,
  createDancerStatus: status.IDLE,
};

const slice = createSlice({
  name: 'parentDetails',
  initialState,
  reducers: {
    resetState(state) {
      Object.assign(state, initialState);
    },
    resetStatus(state, { payload }) {
      state[payload] = status.IDLE;
    },
    setPage: (state, { payload }) => {
      state.notifications.currentPage = payload;
    },
    createPendingRelative(state) {
      state.editDancers.push({ _id: uuid(), status: 'pending' });
    },
    updateRelative(state, { payload }) {
      const relativeIndex = state.editDancers.findIndex(
        (g) => g._id === payload._id
      );

      if (relativeIndex !== -1) {
        state.editDancers[relativeIndex] = {
          ...state.editDancers[relativeIndex],
          ...payload.data,
          status: 'saved',
        };
      }
    },
    removeRelative(state, { payload }) {
      const relativeIndex = state.editDancers.findIndex(
        (g) => g._id === payload
      );

      if (relativeIndex !== -1) {
        state.editDancers.splice(relativeIndex, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getParentInfo.pending, (state) => {
        state.loadingParentInfoStatus = status.PENDING;
      })
      .addCase(thunks.getParentInfo.fulfilled, (state, { payload }) => {
        const relationships = payload.data.other_guardians || [];

        state.parentInfo = parentDefaultValuesModel(payload.data);
        state.relationships = relationships.map((r) => ({
          ...relativeEditModel({
            ...r,
            roles: relativesRoles,
            pivot: relationships[0].pivot,
            id: { value: r.id, label: r.full_name },
            _id: uuid(),
          }),
          status: 'saved',
        }));
        state.loadingParentInfoStatus = status.SUCCESS;
      })
      .addCase(thunks.getParentInfo.rejected, (state) => {
        state.loadingParentInfoStatus = status.FAIL;
      })

      .addCase(thunks.createRelative.pending, (state) => {
        state.createDancerStatus = status.PENDING;
      })
      .addCase(thunks.createRelative.fulfilled, (state, { payload }) => {
        const relativeIndex = state.editDancers.findIndex(
          (g) => g._id === payload._id
        );
        if (relativeIndex !== -1) {
          state.editDancers[relativeIndex] = {
            ...state.editDancers[relativeIndex],
            ...createdRelativeResponseModel({
              ...payload.relative,
              roles: dancerRoles,
            }),
            status: 'saved',
          };
        }
        state.createDancerStatus = status.SUCCESS;
      })

      .addCase(thunks.getNotifications.pending, (state) => {
        state.loadingNotificationsStatus = status.PENDING;
      })
      .addCase(thunks.getNotifications.fulfilled, (state, { payload }) => {
        state.notifications.list = payload.data;
        state.loadingNotificationsStatus = status.SUCCESS;
      })
      .addCase(thunks.getNotifications.rejected, (state) => {
        state.loadingNotificationsStatus = status.FAIL;
      })

      .addCase(thunks.getDancersList.pending, (state) => {
        state.loadingDancersStatus = status.PENDING;
      })
      .addCase(thunks.getDancersList.fulfilled, (state, { payload }) => {
        state.dancers = payload.data;

        state.editDancers = payload.data.map((r) => ({
          ...relativeEditModel({
            ...r,
            roles: dancerRoles,
            pivot: { is_primary_contact: r.is_primary_contact },
            role: r.student_role,
            id: { value: r.id, label: `${r.first_name} ${r.last_name}` },
            _id: uuid(),
          }),
          status: 'saved',
        }));
        state.loadingDancersStatus = status.SUCCESS;
      })
      .addCase(thunks.getDancersList.rejected, (state) => {
        state.loadingDancersStatus = status.FAIL;
      })

      .addCase(thunks.getClasses.fulfilled, (state, { payload }) => {
        const preparedClasses = payload.data.flatMap((dancer) =>
          dancer.classes.map((classItem) => ({
            ...classItem,
            dancerName: `${dancer.dancerFirstName} ${dancer.dancerLastName}`,
          }))
        );

        state.parentClasses = preparedClasses;
      });
  },
});

const parentDetails = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { parentDetails };
export default slice.reducer;
