import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import 'react-toastify/dist/ReactToastify.css';

import store from 'store';
import App from 'App';
import Theme from 'components/App/Theme';
import { ComponentsGlobalStyles } from 'components/App/GlobalStyled/global/components';
import { RebootStyles } from 'components/App/GlobalStyled/global/reboot';
import { GlobalStyles } from 'components/App/GlobalStyled/global';

const persist = persistStore(store);

const container = document.getElementById(`root`);
const root = ReactDOM.createRoot(container);

root.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate
        loading={null}
        persistor={persist}
      >
        <Theme>
          <RebootStyles />
          <ComponentsGlobalStyles />
          <GlobalStyles />
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Theme>
      </PersistGate>
      <ToastContainer
        position="top-right"
        transition={Slide}
        autoClose={3000}
        newestOnTop={false}
        closeButton={false}
        hideProgressBar
        draggable
        pauseOnHover
        theme="light"
      />
    </Provider>
  </StrictMode>
);
