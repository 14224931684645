export const createdRelativeResponseModel = ({
  id,
  role,
  is_primary_contact,
  roles,
}) => {
  const preparedRole = roles.find((r) => r.label === role);

  return {
    id,
    role: preparedRole,
    is_primary_contact,
  };
};
