import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { WEB_API_ROUTES } from 'api/api-routes';
import { handleServerErrors } from 'utils/server-errors';
import { showToast } from 'utils/showToast';

const getEventInfo = createAsyncThunk(
  'eventDetails/getEventInfo',
  async ({ eventId }) => {
    try {
      const response = await api.get(
        WEB_API_ROUTES.events.eventInfo.replace('{eventId}', eventId)
      );

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const sendEmail = createAsyncThunk(
  'eventDetails/sendEmail',
  async ({ subject, messageText, messageFor }) => {
    try {
      const response = await api.post(WEB_API_ROUTES.events.sendEmail, {
        messageFor,
        subject,
        messageText,
      });
      showToast.success('Message sent');

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const sendSms = createAsyncThunk(
  'eventDetails/sendSms',
  async ({ messageText, messageFor }) => {
    try {
      const response = await api.post(WEB_API_ROUTES.dancers.sendSms, {
        messageFor,
        messageText,
      });
      showToast.success('Message sent');

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getEventPerformances = createAsyncThunk(
  'eventDetails/getEventPerformances',
  async (eventId) => {
    try {
      const response = await api.get(
        WEB_API_ROUTES.events.eventPerformances.replace('{eventId}', eventId)
      );

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getEventInfo,
  sendEmail,
  sendSms,
  getEventPerformances,
};

export { thunks };
