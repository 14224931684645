import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { WEB_API_ROUTES } from 'api/api-routes';
import { handleServerErrors } from 'utils/server-errors';
import { showToast } from 'utils/showToast';
import { createParent } from '../createParent';

const getParentInfo = createAsyncThunk(
  'parentDetails/getParentInfo',
  async ({ parentId }) => {
    try {
      const res = await api.get(
        WEB_API_ROUTES.parents.parentInfo.replace('{parentId}', parentId)
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updateParentInfo = createAsyncThunk(
  'parentDetails/updateParentInfo',
  async ({ parentId, data }) => {
    try {
      const res = await api.post(
        WEB_API_ROUTES.parents.editParent.replace('{parentId}', parentId),
        data
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const createRelative = createAsyncThunk(
  'parentDetails/createRelative',
  async ({ data, _id }, { dispatch }) => {
    try {
      const { data: relativeData } = await api.post(
        WEB_API_ROUTES.parents.createRelative,
        data
      );

      const newRelativeId = {
        value: relativeData.data.relative.id,
        label: `${relativeData.data.relative.first_name} ${relativeData.data.relative.last_name}`,
      };

      dispatch(createParent.actions.addCreatedRelative(newRelativeId));

      showToast.success('Child successfully added to the system');

      return {
        relative: { ...relativeData.data.relative, id: newRelativeId },
        _id,
      };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getNotifications = createAsyncThunk(
  'parentDetails/getNotifications',
  async (parentId) => {
    try {
      const res = await api.get(
        WEB_API_ROUTES.parents.parentNotifications.replace(
          '{parentId}',
          parentId
        )
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getDancersList = createAsyncThunk(
  'parentDetails/getDancersList',
  async ({ parentId }) => {
    try {
      const res = await api.get(
        WEB_API_ROUTES.parents.dancersList.replace('{parentId}', parentId)
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getClasses = createAsyncThunk(
  'parents/getDancers',
  async ({ parentId }) => {
    try {
      const response = await api.get(
        WEB_API_ROUTES.parents.parentClasses.replace('{parentId}', parentId)
      );

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getParentInfo,
  createRelative,
  updateParentInfo,
  getNotifications,
  getDancersList,
  getClasses,
};

export { thunks };
