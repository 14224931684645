const selectors = {
  defaultRoutineInfo: (state) => state.createRoutine.defaultRoutineInfo,
  submitCreationStatus: (state) => state.createRoutine.submitCreationStatus,
  styles: (state) => state.createRoutine.styles,
  levels: (state) => state.createRoutine.levels,
  types: (state) => state.createRoutine.types,
  statuses: (state) => state.createRoutine.statuses,
};

export { selectors };
