import { showToast } from './showToast';

export const toastError = (msg) => showToast.error(msg);

export const handleServerErrors = (error) => {
  if (!error?.response) return;
  const { data, status } = error.response;

  if (status === 404) return;
  if (data?.message === 'Unauthenticated.') return;

  console.log('%c => Error ', 'background: red; color: #000', error.response);

  if (data && data?.errors && !Array.isArray(data?.errors)) {
    Object.values(data.errors).forEach((errors) => {
      if (Array.isArray(errors)) {
        toastError(errors.join(' '));
        return;
      }

      toastError(errors);
    });
  } else if (data && data?.message) {
    toastError(data.message);
  }
  // } else {
  //   toastError(`Error ${status}: ${statusText}`);
  // }
};
