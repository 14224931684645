import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { WEB_API_ROUTES } from 'api/api-routes';
import { handleServerErrors } from 'utils/server-errors';

const getStyles = createAsyncThunk('createClass/getStyles', async () => {
  try {
    const res = await api.get(WEB_API_ROUTES.classes.styles);
    return res.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const submitCreation = createAsyncThunk(
  'createClass/submitCreation',
  async (data) => {
    try {
      const res = await api.post(WEB_API_ROUTES.classes.createClass, data);
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getStyles,
  submitCreation,
};

export { thunks };
