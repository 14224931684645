import { createAsyncThunk } from '@reduxjs/toolkit';

import { WEB_API_ROUTES } from 'api/api-routes';
import { handleServerErrors } from 'utils/server-errors';
import api from 'api';

const getTeachers = createAsyncThunk('scheduler/getTeachers', async () => {
  try {
    const response = await api.get(WEB_API_ROUTES.scheduler.teachers);

    return response.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const getStudents = createAsyncThunk('scheduler/getStudents', async () => {
  try {
    const response = await api.get(WEB_API_ROUTES.scheduler.students);

    return response.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const getLocations = createAsyncThunk('scheduler/getLocations', async () => {
  try {
    const response = await api.get(WEB_API_ROUTES.scheduler.locations);

    return response.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const getParents = createAsyncThunk('scheduler/getParents', async () => {
  try {
    const response = await api.get(WEB_API_ROUTES.scheduler.parents);

    return response.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const getClasses = createAsyncThunk('scheduler/getClasses', async () => {
  try {
    const res = await api.get(WEB_API_ROUTES.scheduler.classes);

    return res.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const getAppointments = createAsyncThunk(
  'scheduler/getAppointments',
  async ({ start, end }) => {
    try {
      const response = await api.get(WEB_API_ROUTES.scheduler.appointments, {
        params: { start, end },
      });

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const storeAppointment = createAsyncThunk(
  'scheduler/storeAppointment',
  async (data) => {
    try {
      const response = await api.post(
        WEB_API_ROUTES.scheduler.storeAppointment,
        data
      );

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updateAppointment = createAsyncThunk(
  'scheduler/updateAppointment',
  async (data) => {
    try {
      const response = await api.post(
        WEB_API_ROUTES.scheduler.updateAppointment,
        data
      );

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const deleteAppointment = createAsyncThunk(
  'scheduler/updateAppointment',
  async (appointmentId) => {
    try {
      const response = await api.delete(
        `${WEB_API_ROUTES.scheduler.deleteAppointment}/${appointmentId}`
      );

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const removeAppointmentMedia = createAsyncThunk(
  'scheduler/removeAppointmentMedia',
  async (medias) => {
    try {
      const response = await api.post(
        WEB_API_ROUTES.scheduler.removeAppointmentMedia,
        { medias }
      );

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const rejectRequest = createAsyncThunk(
  'scheduler/rejectRequest',
  async ({ requestId }) => {
    try {
      const response = await api.post(WEB_API_ROUTES.scheduler.rejectRequest, {
        request_id: requestId,
      });

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const approveRequest = createAsyncThunk(
  'scheduler/approveRequest',
  async (data) => {
    try {
      const response = await api.post(
        WEB_API_ROUTES.scheduler.approveRequest,
        data
      );

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const sendEmail = createAsyncThunk(
  'scheduler/sendEmail',
  async ({ subject, messageText, messageFor }) => {
    try {
      const response = await api.post(WEB_API_ROUTES.scheduler.sendEmail, {
        messageFor,
        subject,
        messageText,
      });

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const sendSms = createAsyncThunk(
  'scheduler/sendSms',
  async ({ messageText, messageFor }) => {
    try {
      const response = await api.post(WEB_API_ROUTES.scheduler.sendSms, {
        messageFor,
        messageText,
      });

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const cancelAppointment = createAsyncThunk(
  'scheduler/cancelAppointment',
  async (appointmentId) => {
    try {
      const response = await api.delete(
        WEB_API_ROUTES.scheduler.cancelAppointment.replace(
          '{appointmentId}',
          appointmentId
        )
      );

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updateRating = createAsyncThunk(
  'scheduler/updateRating',
  async ({ type, value, studentId, scheduleId }) => {
    try {
      const response = await api.post(WEB_API_ROUTES.scheduler.updateRating, {
        type,
        value,
        student_id: studentId,
        schedule_id: scheduleId,
      });

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updateAttendance = createAsyncThunk(
  'scheduler/updateAttendance',
  async ({ studentId, scheduleId, attendance }) => {
    try {
      const response = await api.post(
        WEB_API_ROUTES.scheduler.updateAttendance,
        {
          student_id: studentId,
          schedule_id: scheduleId,
          attendance,
        }
      );

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const storeUnavailable = createAsyncThunk(
  'scheduler/storeUnavailable',
  async (data) => {
    try {
      const response = await api.post(
        WEB_API_ROUTES.scheduler.storeUnavailable,
        data
      );

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getUsers = createAsyncThunk('scheduler/getUsers', async () => {
  try {
    const response = await api.get(WEB_API_ROUTES.scheduler.users);

    return response.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const updateUnavailable = createAsyncThunk(
  'scheduler/updateUnavailable',
  async (data) => {
    try {
      const appointmentId = data.get('appointment_id');
      data.delete('appointment_id');

      const response = await api.post(
        WEB_API_ROUTES.scheduler.updateUnavailable.replace(
          '{appointmentId}',
          appointmentId
        ),
        data
      );

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const storeInternal = createAsyncThunk(
  'scheduler/storeInternal',
  async (data) => {
    try {
      const response = await api.post(
        WEB_API_ROUTES.scheduler.storeInternal,
        data
      );

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updateInternal = createAsyncThunk(
  'scheduler/updateInternal',
  async (data) => {
    try {
      const appointmentId = data.get('appointment_id');
      data.delete('appointment_id');

      const response = await api.post(
        WEB_API_ROUTES.scheduler.updateInternal.replace(
          '{appointmentId}',
          appointmentId
        ),
        data
      );

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getTeachers,
  getStudents,
  getAppointments,
  getLocations,
  getParents,
  getClasses,
  storeAppointment,
  updateAppointment,
  deleteAppointment,
  removeAppointmentMedia,
  rejectRequest,
  approveRequest,
  sendEmail,
  sendSms,
  cancelAppointment,
  updateRating,
  updateAttendance,
  storeUnavailable,
  getUsers,
  updateUnavailable,
  storeInternal,
  updateInternal,
};

export { thunks };
