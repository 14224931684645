import uuid from 'react-uuid';
import { createSlice } from '@reduxjs/toolkit';

import { relativesRoles, status } from 'utils/const';
import { createdRelativeResponseModel } from 'models/createdRelativeResponseModel';
import { relativeModel } from 'models/relativeModel';
import { selectors } from './selectors';
import { thunks } from './thunks';

const defaultValues = {
  image: null,
  first_name: '',
  last_name: '',
  birthdate: null,
  phone: '',
  email: '',
  address_line_1: '',
  address_line_2: '',
  city: '',
  state: { label: '', value: '' },
  zipcode: '',
  country: { label: 'USA', value: 'USA' },

  height: '',
  bust: '',
  waist: '',
  hip: '',
  girth: '',
  inseam: '',
  head: '',
  tights: '',
  shoe_size: '',
  last_updated: null,
};

const initialState = {
  defaultDancerInfo: defaultValues,
  guardians: [],
  relatives: [],
  createGuardianStatus: status.IDLE,
  submitDancerCreationStatus: status.IDLE,
  fetchingRelativesStatus: status.IDLE,
};

const slice = createSlice({
  name: 'createDancer',
  initialState,
  reducers: {
    resetState(state) {
      Object.assign(state, initialState);
    },
    addRelative(state, { payload }) {
      state.relatives.push(payload);
    },
    addGuardian(state) {
      state.guardians.push({ _id: uuid(), status: 'pending' });
    },
    updateGuardian(state, { payload }) {
      const guardianIndex = state.guardians.findIndex(
        (g) => g._id === payload._id
      );

      if (guardianIndex !== -1) {
        state.guardians[guardianIndex] = {
          ...state.guardians[guardianIndex],
          ...payload.data,
          status: 'saved',
        };
      }
    },
    removeGuardian(state, { payload }) {
      const guardianIndex = state.guardians.findIndex((g) => g._id === payload);

      if (guardianIndex !== -1) {
        state.guardians.splice(guardianIndex, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.submitDancerCreation.pending, (state) => {
        state.submitDancerCreationStatus = status.PENDING;
      })
      .addCase(thunks.submitDancerCreation.fulfilled, (state) => {
        state.submitDancerCreationStatus = status.SUCCESS;
      })
      .addCase(thunks.submitDancerCreation.rejected, (state) => {
        state.submitDancerCreationStatus = status.FAIL;
      })

      .addCase(thunks.createGuardian.pending, (state) => {
        state.createGuardianStatus = status.PENDING;
      })
      .addCase(thunks.createGuardian.fulfilled, (state, { payload }) => {
        const guardianIndex = state.guardians.findIndex(
          (g) => g._id === payload._id
        );

        if (guardianIndex !== -1) {
          state.guardians[guardianIndex] = {
            ...state.guardians[guardianIndex],
            ...createdRelativeResponseModel({
              ...payload.relative,
              roles: relativesRoles,
            }),
            status: 'saved',
          };
        }
        state.createGuardianStatus = status.SUCCESS;
      })
      .addCase(thunks.createGuardian.rejected, (state) => {
        state.createGuardianStatus = status.FAIL;
      })

      .addCase(thunks.getRelatives.pending, (state) => {
        state.fetchingRelativesStatus = status.PENDING;
      })
      .addCase(thunks.getRelatives.fulfilled, (state, { payload }) => {
        // TODO: flat on presentation level?
        state.relatives = Object.values(payload)
          .flat()
          .map((relative) => relativeModel(relative));
        state.fetchingRelativesStatus = status.SUCCESS;
      })
      .addCase(thunks.getRelatives.rejected, (state) => {
        state.fetchingRelativesStatus = status.FAIL;
      });
  },
});

const createDancer = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { createDancer };
export default slice.reducer;
